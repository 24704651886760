var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.page)?_c('div',[_c('h2',[_vm._v(_vm._s(_vm.$t('unilevel-title')))]),_c('div',{staticClass:"stats-box"},[(_vm.current)?_c('div',{staticClass:"position-fixed align-floating-box"},[_c('unilevel-info-box',{staticClass:"info-box scroll-area h-80v",attrs:{"user":_vm.current},on:{"update:user":function($event){_vm.current=$event}}})],1):_vm._e()]),_c('div',{staticClass:"d-block d-sm-flex"},[_c('div',{staticClass:"unilevel-tree d-block d-sm-inline-flex"},[(_vm.users)?_c('div',{staticClass:"mb-3 nav-justified mt-3 fsize-1"},[_c('v-treeview',{attrs:{"active":_vm.active,"open":_vm.open,"items":_vm.users,"rounded":"","open-on-click":"","item-key":"username","activatable":"","transition":"","dense":""},on:{"update:active":function($event){_vm.active=$event},"update:open":function($event){_vm.open=$event}},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('div',{staticClass:"avatar-icon-wrapper",class:_vm.getRankClass(item.totals.rank),on:{"click":function($event){return _vm.selectUser(item)}}},[_c('div',{staticClass:"avatar-icon",class:['bg-'+_vm.getRankClass(item.totals.rank), (item.activePackage == null) ? ' no-pack': '']},[(item.totals.rank > 0)?_c('font-awesome-icon',{staticClass:"fsize-05",attrs:{"icon":_vm.rankIcon(item.totals.rank)}}):_vm._e()],1)])]}},{key:"label",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"fsize-1",on:{"click":function($event){return _vm.selectUser(item)}}},[_vm._v(_vm._s(item.username))])]}},{key:"append",fn:function(ref){
var item = ref.item;
return [(item.createdAt === _vm.page.viewer.totals.strongestDirect)?_c('span',{staticClass:"fsize-1 font-weight-bold"},[_vm._v("*")]):_vm._e()]}}],null,false,396953660)})],1):_vm._e()]),_c('div',{staticClass:"unilevel-infobox-mobile"},[(_vm.current)?_c('div',{},[_c('unilevel-info-box',{staticClass:"info-box",attrs:{"user":_vm.current},on:{"update:user":function($event){_vm.current=$event}}})],1):_vm._e()])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }