<template>
    <div v-if="page">
        <h2>{{$t('unilevel-title')}}</h2>
        <div class="stats-box">
            <div class="position-fixed align-floating-box" v-if="current">
                <unilevel-info-box class="info-box scroll-area h-80v" :user.sync="current" />
            </div>
        </div>
        <div class="d-block d-sm-flex">
            <div class="unilevel-tree d-block d-sm-inline-flex">
                <div class="mb-3 nav-justified mt-3 fsize-1" v-if="users">
                    <v-treeview
                        :active.sync="active"
                        :open.sync="open"
                        :items="users"
                        rounded
                        open-on-click
                        item-key="username"
                        activatable
                        transition
                        dense
                    >
                        <template v-slot:prepend="{ item, index }" >
                            <div class="avatar-icon-wrapper" :class="getRankClass(item.totals.rank)" @click="selectUser(item)" >
                                <div class="avatar-icon" :class="['bg-'+getRankClass(item.totals.rank), (item.activePackage == null) ? ' no-pack': '']"  >
                                    <font-awesome-icon v-if="item.totals.rank > 0" :icon="rankIcon(item.totals.rank)" class="fsize-05" />
                                </div>
                            </div>
                        </template>
                        <template v-slot:label="{item}">
                            <span @click="selectUser(item)" class="fsize-1">{{item.username}}</span>
                        </template>
                        <template v-slot:append="{item}">
                            <span v-if="item.createdAt === page.viewer.totals.strongestDirect" class="fsize-1 font-weight-bold">*</span>
                        </template>
                    </v-treeview>
                </div>
            </div>
            <div class="unilevel-infobox-mobile">
                <div class="" v-if="current">
                    <unilevel-info-box class="info-box" :user.sync="current" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
    import { library } from '@fortawesome/fontawesome-svg-core'
    import { faMale, faMedal, faTrophy, faGem, faCrown } from '@fortawesome/free-solid-svg-icons'
    import { faGem as faGemS } from '@fortawesome/free-regular-svg-icons'
    library.add(faMale, faMedal, faTrophy, faGem, faGemS, faCrown);
    import UnilevelInfoBox from "@/components/Tree/UnilevelInfoBox";
    import gql from "graphql-tag";
    export default {
        name: "UnilevelPage",
        components: {
            UnilevelInfoBox,
            "font-awesome-icon": FontAwesomeIcon,
        },
        data() {
            return {
                heading: this.$i18n.t("Unilevel"),
                page: null,
                current: null,
                users: null,
                open: [],
                active: [],
                selected: {
                    username: "",
                    items: []
                }
            }
        },
        computed: {
        },
        methods: {
            getRankClass: function(rank) {
                const rankColors = [
                    "prospect",
                    "member",
                    "bronze",
                    "silver",
                    "gold",
                    "platinum",
                    "ruby",
                    "sapphire",
                    "emerald",
                    "diamond",
                    "blue-diamond",
                    "black-diamond",
                    "royal-diamond",
                    "crown",
                    "founder"
                ];
                return rankColors[rank];
            },
            rankIcon: function (rank) {
                const rankIcons = [
                    [""],
                    ["fas", "male"],
                    [""],
                    [""],
                    [""],
                    ["fas", "trophy"],
                    ["fas", "trophy"],
                    ["fas", "gem"],
                    ["fas", "gem"],
                    ["far", "gem"],
                    ["far", "gem"],
                    ["far", "gem"],
                    ["far", "gem"],
                    ["fas", "crown"],
                    ["fas", "crown"],
                ];
                return rankIcons[rank];
            },
            selectUser(item) {
                this.current = item
            }
        },
        apollo: {
            page: {
                query: gql`query{
                    viewerIsAdmin,
                    viewer{
                        id,createdAt,username,firstName,lastName,email,country,activePackage,poolStatus,sponsorLeg,sponsorDepth,
                        totals{
                        rank,tokens,shares,sharesA,held,activeVolumeLeft,activeVolumeRight,activeFiatLeft,activeFiatRight,inactiveVolumeLeft,inactiveVolumeRight,unpaidVolumeLeft,unpaidVolumeRight,strongestDirect},
                        userBinaryTotals{countLeft,countRight,countNewLeft,countNewRight},
                        children{
                            id,createdAt,username,firstName,lastName,email,country,hasDownline,activePackage,poolStatus,sponsorLeg,sponsorDepth,
                            totals{rank,tokens,shares,sharesA,held,activeVolumeLeft,activeVolumeRight,activeFiatLeft,activeFiatRight,inactiveVolumeLeft,inactiveVolumeRight,unpaidVolumeLeft,unpaidVolumeRight},
                            userBinaryTotals{countLeft,countRight,countNewLeft,countNewRight}
                            children{
                                id,createdAt,username,firstName,lastName,email,country,hasDownline,activePackage,poolStatus,sponsorLeg,sponsorDepth,
                                totals{rank,tokens,shares,sharesA,held,activeVolumeLeft,activeVolumeRight,activeFiatLeft,activeFiatRight,inactiveVolumeLeft,inactiveVolumeRight,unpaidVolumeLeft,unpaidVolumeRight},
                                userBinaryTotals{countLeft,countRight,countNewLeft,countNewRight}
                                children{
                                    id,createdAt,username,firstName,lastName,email,country,hasDownline,activePackage,poolStatus,sponsorLeg,sponsorDepth,
                                    totals{rank,tokens,shares,sharesA,held,activeVolumeLeft,activeVolumeRight,activeFiatLeft,activeFiatRight,inactiveVolumeLeft,inactiveVolumeRight,unpaidVolumeLeft,unpaidVolumeRight},
                                    userBinaryTotals{countLeft,countRight,countNewLeft,countNewRight}
                                    children{
                                        id,createdAt,username,firstName,lastName,email,country,hasDownline,activePackage,poolStatus,sponsorLeg,sponsorDepth,
                                        totals{rank,tokens,shares,sharesA,held,activeVolumeLeft,activeVolumeRight,activeFiatLeft,activeFiatRight,inactiveVolumeLeft,inactiveVolumeRight,unpaidVolumeLeft,unpaidVolumeRight},
                                        userBinaryTotals{countLeft,countRight,countNewLeft,countNewRight}
                                        children{
                                            id,createdAt,username,firstName,lastName,email,country,hasDownline,activePackage,poolStatus,sponsorLeg,sponsorDepth,
                                            totals{rank,tokens,shares,sharesA,held,activeVolumeLeft,activeVolumeRight,activeFiatLeft,activeFiatRight,inactiveVolumeLeft,inactiveVolumeRight,unpaidVolumeLeft,unpaidVolumeRight},
                                            userBinaryTotals{countLeft,countRight,countNewLeft,countNewRight}
                                            children{
                                                id,createdAt,username,firstName,lastName,email,country,hasDownline,activePackage,poolStatus,sponsorLeg,sponsorDepth,
                                                totals{rank,tokens,shares,sharesA,held,activeVolumeLeft,activeVolumeRight,activeFiatLeft,activeFiatRight,inactiveVolumeLeft,inactiveVolumeRight,unpaidVolumeLeft,unpaidVolumeRight},
                                                userBinaryTotals{countLeft,countRight,countNewLeft,countNewRight}
                                                children{
                                                    id,createdAt,username,firstName,lastName,email,country,hasDownline,activePackage,poolStatus,sponsorLeg,sponsorDepth,
                                                    totals{rank,tokens,shares,sharesA,held,activeVolumeLeft,activeVolumeRight,activeFiatLeft,activeFiatRight,inactiveVolumeLeft,inactiveVolumeRight,unpaidVolumeLeft,unpaidVolumeRight},
                                                    userBinaryTotals{countLeft,countRight,countNewLeft,countNewRight}
                                                    children{
                                                        id,createdAt,username,firstName,lastName,email,country,hasDownline,activePackage,poolStatus,sponsorLeg,sponsorDepth,
                                                        totals{rank,tokens,shares,sharesA,held,activeVolumeLeft,activeVolumeRight,activeFiatLeft,activeFiatRight,inactiveVolumeLeft,inactiveVolumeRight,unpaidVolumeLeft,unpaidVolumeRight},
                                                        userBinaryTotals{countLeft,countRight,countNewLeft,countNewRight}
                                                        children{
                                                            id,createdAt,username,firstName,lastName,email,country,hasDownline,activePackage,poolStatus,sponsorLeg,sponsorDepth,
                                                            totals{rank,tokens,shares,sharesA,held,activeVolumeLeft,activeVolumeRight,activeFiatLeft,activeFiatRight,inactiveVolumeLeft,inactiveVolumeRight,unpaidVolumeLeft,unpaidVolumeRight},
                                                            userBinaryTotals{countLeft,countRight,countNewLeft,countNewRight}
                                                            children{
                                                                id,createdAt,username,firstName,lastName,email,country,hasDownline,activePackage,poolStatus,sponsorLeg,sponsorDepth,
                                                                totals{rank,tokens,shares,sharesA,held,activeVolumeLeft,activeVolumeRight,activeFiatLeft,activeFiatRight,inactiveVolumeLeft,inactiveVolumeRight,unpaidVolumeLeft,unpaidVolumeRight},
                                                                userBinaryTotals{countLeft,countRight,countNewLeft,countNewRight}
                                                                children{
                                                                    id,createdAt,username,firstName,lastName,email,country,hasDownline,activePackage,poolStatus,sponsorLeg,sponsorDepth,
                                                                    totals{rank,tokens,shares,sharesA,held,activeVolumeLeft,activeVolumeRight,activeFiatLeft,activeFiatRight,inactiveVolumeLeft,inactiveVolumeRight,unpaidVolumeLeft,unpaidVolumeRight},
                                                                    userBinaryTotals{countLeft,countRight,countNewLeft,countNewRight}
                                                                    children{
                                                                        id,createdAt,username,firstName,lastName,email,country,hasDownline,activePackage,poolStatus,sponsorLeg,sponsorDepth,
                                                                        totals{rank,tokens,shares,sharesA,held,activeVolumeLeft,activeVolumeRight,activeFiatLeft,activeFiatRight,inactiveVolumeLeft,inactiveVolumeRight,unpaidVolumeLeft,unpaidVolumeRight},
                                                                        userBinaryTotals{countLeft,countRight,countNewLeft,countNewRight}
                                                                        children{
                                                                            id,createdAt,username,firstName,lastName,email,country,hasDownline,activePackage,poolStatus,sponsorLeg,sponsorDepth,
                                                                            totals{rank,tokens,shares,sharesA,held,activeVolumeLeft,activeVolumeRight,activeFiatLeft,activeFiatRight,inactiveVolumeLeft,inactiveVolumeRight,unpaidVolumeLeft,unpaidVolumeRight},
                                                                            userBinaryTotals{countLeft,countRight,countNewLeft,countNewRight}
                                                                            children{
                                                                                id,createdAt,username,firstName,lastName,email,country,hasDownline,activePackage,poolStatus,sponsorLeg,sponsorDepth,
                                                                                totals{rank,tokens,shares,sharesA,held,activeVolumeLeft,activeVolumeRight,activeFiatLeft,activeFiatRight,inactiveVolumeLeft,inactiveVolumeRight,unpaidVolumeLeft,unpaidVolumeRight},
                                                                                userBinaryTotals{countLeft,countRight,countNewLeft,countNewRight}
                                                                                children{
                                                                                    id,createdAt,username,firstName,lastName,email,country,hasDownline,activePackage,poolStatus,sponsorLeg,sponsorDepth,
                                                                                    totals{rank,tokens,shares,sharesA,held,activeVolumeLeft,activeVolumeRight,activeFiatLeft,activeFiatRight,inactiveVolumeLeft,inactiveVolumeRight,unpaidVolumeLeft,unpaidVolumeRight},
                                                                                    userBinaryTotals{countLeft,countRight,countNewLeft,countNewRight}
                                                                                    children{
                                                                                        id,createdAt,username,firstName,lastName,email,country,hasDownline,activePackage,poolStatus,sponsorLeg,sponsorDepth,
                                                                                        totals{rank,tokens,shares,sharesA,held,activeVolumeLeft,activeVolumeRight,activeFiatLeft,activeFiatRight,inactiveVolumeLeft,inactiveVolumeRight,unpaidVolumeLeft,unpaidVolumeRight},
                                                                                        userBinaryTotals{countLeft,countRight,countNewLeft,countNewRight}
                                                                                        children{
                                                                                            id,createdAt,username,firstName,lastName,email,country,hasDownline,activePackage,poolStatus,sponsorLeg,sponsorDepth,
                                                                                            totals{rank,tokens,shares,sharesA,held,activeVolumeLeft,activeVolumeRight,activeFiatLeft,activeFiatRight,inactiveVolumeLeft,inactiveVolumeRight,unpaidVolumeLeft,unpaidVolumeRight},
                                                                                            userBinaryTotals{countLeft,countRight,countNewLeft,countNewRight}
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }`,
                update: function(data) {
                    data.viewer._username = data.viewer.username
                    this.users = [data.viewer]
                    this.selected.username = data.viewer.username
                    this.selected.items = this.users
                    this.open.push(data.viewer.username)
                    this.current = data.viewer
                    this.$store.commit('user/updateAvailable', {
                        tokens: data.viewer.totals.tokens
                    })
                    if (data.viewerIsAdmin) {
                        this.$store.commit('user/setAdmin')
                    }
                    console.log(data.viewer.createdAt)
                    return data
                },
            }
        },
    }
</script>
<style scoped>
.align-floating-box {
    right: 15px;
    top: 80px;
}
.avatar-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border: black solid 1px;
    width: 16px !important;
    height: 16px !important;
}
.bg-prospect {
}
.bg-member {
}
.bg-bronze {
    background-color: #CD7F32 !important;
    color: white;
}
.bg-silver {
    background-color: #C0C0C0 !important;
}
.bg-gold {
    background-color: #FFD700 !important;
}
.bg-platinum {
    background-color: #E5E4E2!important;
}
.bg-sapphire {
    background-color: #000A66 !important;;
    color: white !important;
}
.bg-emerald {
    background-color: #027218 !important;
    color: white;
}
.bg-diamond {
    background-color: #BFEFFF !important;
}
.bg-royal-diamond {
    background-color:  #C0C0C0 !important;
}
.bg-crown {
    background-color: #FFD700 !important;
}
.prospect {
}
.member {
}
.bronze {
    border: #CD7F32 solid 1px;
}
.bg-silver {
    border: #C0C0C0 solid 1px;
}
.bg-gold {
    border: #FFD700 solid 1px;
}
.bg-platinum {
    border: #E5E4E2 solid 1px;
}
.bg-sapphire {
    border: #000A66 solid 1px;
}
.bg-emerald {
    border: #027218 solid 1px;
}
.bg-diamond {
    border: #BFEFFF solid 1px;
}
.bg-royal-diamond {
    border: #C0C0C0 solid 1px;
}
.bg-crown {
    border: #FFD700 solid 1px;
}
.no-pack {
    border: red solid 1px !important;
}
</style>