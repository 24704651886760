<template>
    <div class="card-shadow-primary card-border mb-3 card w-auto">
        <div class="dropdown-menu-header">
            <div class="dropdown-menu-header-inner bg-primary p-2">
                <div class="menu-header-content">
                    <div>
                        <h5 class="menu-header-title">{{ user.firstName }} {{user.lastName}}</h5>
                    </div>
                </div>
            </div>
        </div>
        <div class=" ">
            <div class="scrollbar-container">
                <ul class="list-group list-group-flush">
                    <li class="list-group-item py-1">
                        <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                                <div class="widget-content-left center-elem mr-2 row-icon">
                                    <i class="pe-7s-user text-muted fsize-3"></i>
                                </div>
                                <div class="widget-content-left field-min-width text-right mr-1">
                                    <div class="widget-heading fsize-1">{{ $t('username')}}:</div>
                                </div>
                                <div class="widget-content-left">
                                    <div class="font-weight-bolder fsize-1">{{ user.username }}</div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="list-group-item py-1">
                        <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                                <div class="widget-content-left center-elem mr-2 row-icon">
                                    <i class="pe-7s-mail text-muted fsize-3"></i>
                                </div>
                                <div class="widget-content-left field-min-width text-right mr-1">
                                    <div class="widget-heading fsize-1">{{ $t('email') }}:</div>
                                </div>
                                <div class="widget-content-left">
                                    <div class="font-weight-bolder fsize-1">{{ user.email }}</div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="list-group-item py-1">
                        <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                                <div class="widget-content-left center-elem mr-2 row-icon">
                                    <i class="pe-7s-date text-muted fsize-3"></i>
                                </div>
                                <div class="widget-content-left field-min-width text-right mr-1">
                                    <div class="widget-heading fsize-1">{{ $t('member-since')}}:</div>
                                </div>
                                <div class="widget-content-left">
                                    <div class="font-weight-bolder fsize-1">{{ user.createdAt|formatDateShort }}</div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="list-group-item py-1">
                        <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                                <div class="widget-content-left center-elem mr-2 row-icon">
                                    <i class="pe-7s-medal text-muted fsize-3"></i>
                                </div>
                                <div class="widget-content-left field-min-width text-right mr-1">
                                    <div class="widget-heading fsize-1">{{ $t('rank')}}:</div>
                                </div>
                                <div class="widget-content-left">
                                    <div class="font-weight-bolder fsize-1">{{ getRankName(user.totals.rank) }}</div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="list-group-item py-1">
                        <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                                <div class="widget-content-left center-elem mr-2 row-icon">
                                    <i class="pe-7s-box2 text-muted fsize-3"></i>
                                </div>
                                <div class="widget-content-left field-min-width text-right mr-1">
                                    <div class="widget-heading fsize-1">{{ $t('package')}}:</div>
                                </div>
                                <div class="widget-content-left">
                                    <div class="font-weight-bolder fsize-1">{{ user.activePackage }}</div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="list-group-item py-1">
                        <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                                <div class="widget-content-left center-elem mr-2 row-icon">
                                    <i class="pe-7s-global text-muted fsize-3"></i>
                                </div>
                                <div class="widget-content-left field-min-width text-right mr-1">
                                    <div class="widget-heading fsize-1">{{ $t('pool-status')}}:</div>
                                </div>
                                <div class="widget-content-left">
                                    <div class="font-weight-bolder fsize-1">{{ user.poolStatus|pool }}</div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="list-group-item py-1" v-if="isAdmin">
                        <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                                <div class="widget-content-left center-elem mr-2 row-icon">
                                    <i class="pe-7s-wallet text-muted fsize-3"></i>
                                </div>
                                <div class="widget-content-left field-min-width text-right mr-1">
                                    <div class="widget-heading fsize-1">{{ $t('tokens.available')}}:</div>
                                </div>
                                <div class="widget-content-left">
                                    <div class="font-weight-bolder fsize-1">{{ user.totals.tokens|formatHtl }}</div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="list-group-item py-1" v-if="isAdmin">
                        <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                                <div class="widget-content-left center-elem mr-2 row-icon">
                                    <i class="pe-7s-news-paper text-muted fsize-3"></i>
                                </div>
                                <div class="widget-content-left field-min-width text-right mr-1">
                                    <div class="widget-heading fsize-1">{{ $t('tokens.sharesA')}}:</div>
                                </div>
                                <div class="widget-content-left">
                                    <div class="font-weight-bolder fsize-1">{{ user.totals.sharesA|formatHtl }}</div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="list-group-item py-1" v-if="isAdmin">
                        <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                                <div class="widget-content-left center-elem mr-2 row-icon">
                                    <i class="pe-7s-news-paper text-muted fsize-3"></i>
                                </div>
                                <div class="widget-content-left field-min-width text-right mr-1">
                                    <div class="widget-heading fsize-1">{{ $t('tokens.sharesB')}}:</div>
                                </div>
                                <div class="widget-content-left">
                                    <div class="font-weight-bolder fsize-1">{{ (user.totals.shares + user.totals.held)|formatHtl }}</div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="list-group-item py-1">
                        <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                                <div class="widget-content-left center-elem mr-2 row-icon">
                                    <i class="pe-7s-id text-muted fsize-3"></i>
                                </div>
                                <div class="widget-content-left field-min-width text-right mr-1">
                                    <div class="widget-heading fsize-1">{{ $t('sponsor-leg')}}:</div>
                                </div>
                                <div class="widget-content-left">
                                    <div class="font-weight-bolder fsize-1">{{ getSponsorLeg(user.sponsorLeg) }}</div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="list-group-item py-1">
                        <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                                <div class="widget-content-left center-elem mr-2 row-icon">
                                    <i class="pe-7s-users text-muted fsize-3 mirror-icon"></i>
                                </div>
                                <div class="widget-content-left field-min-width text-right mr-1">
                                    <div class="widget-heading fsize-1">{{ $t('left-users')}}:</div>
                                </div>
                                <div class="widget-content-left">
                                    <div class="font-weight-bolder fsize-1">{{ user.userBinaryTotals.countLeft }}</div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="list-group-item py-1">
                        <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                                <div class="widget-content-left center-elem mr-2 row-icon">
                                    <i class="pe-7s-users text-muted fsize-3"></i>
                                </div>
                                <div class="widget-content-left field-min-width text-right mr-1">
                                    <div class="widget-heading fsize-1">{{ $t('right-users')}}:</div>
                                </div>
                                <div class="widget-content-left">
                                    <div class="font-weight-bolder fsize-1">{{ user.userBinaryTotals.countRight }}</div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="list-group-item py-1">
                        <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                                <div class="widget-content-left center-elem mr-2 row-icon">
                                    <i class="pe-7s-angle-left-circle text-muted fsize-3"></i>
                                </div>
                                <div class="widget-content-left field-min-width text-right mr-1">
                                    <div class="widget-heading fsize-1">{{ $t('volume-left')}} HTL:</div>
                                </div>
                                <div class="widget-content-left">
                                    <div class="font-weight-bolder fsize-1">{{ user.totals.unpaidVolumeLeft|formatHtl }}</div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="list-group-item py-1" >
                        <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                                <div class="widget-content-left center-elem mr-2 row-icon">
                                    <i class="pe-7s-angle-right-circle text-muted fsize-3"></i>
                                </div>
                                <div class="widget-content-left field-min-width text-right mr-1">
                                    <div class="widget-heading fsize-1">{{ $t('volume-right')}} HTL:</div>
                                </div>
                                <div class="widget-content-left">
                                    <div class="font-weight-bolder fsize-1">{{ user.totals.unpaidVolumeRight|formatHtl }}</div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="list-group-item py-1">
                        <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                                <div class="widget-content-left center-elem mr-2 row-icon">
                                    <i class="pe-7s-right-arrow text-muted fsize-3 mirror-icon"></i>
                                </div>
                                <div class="widget-content-left field-min-width text-right mr-1">
                                    <div class="widget-heading fsize-1">{{ $t('lifetime-left')}} HTL:</div>
                                </div>
                                <div class="widget-content-left">
                                    <div class="font-weight-bolder fsize-1">{{ user.totals.activeVolumeLeft|formatHtl }}</div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="list-group-item py-1">
                        <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                                <div class="widget-content-left center-elem mr-2 row-icon">
                                    <i class="pe-7s-right-arrow text-muted fsize-3"></i>
                                </div>
                                <div class="widget-content-left field-min-width text-right mr-1">
                                    <div class="widget-heading fsize-1">{{ $t('lifetime-right')}} HTL:</div>
                                </div>
                                <div class="widget-content-left">
                                    <div class="font-weight-bolder fsize-1">{{ user.totals.activeVolumeRight|formatHtl }}</div>
                                </div>
                            </div>
                        </div>
                    </li>


                    <li class="list-group-item py-1">
                        <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                                <div class="widget-content-left center-elem mr-2 row-icon">
                                    <i class="pe-7s-right-arrow text-muted fsize-3 mirror-icon"></i>
                                </div>
                                <div class="widget-content-left field-min-width text-right mr-1">
                                    <div class="widget-heading fsize-1">{{ $t('lifetime-left')}} PV.:</div>
                                </div>
                                <div class="widget-content-left">
                                    <div class="font-weight-bolder fsize-1">{{ user.totals.activeFiatLeft|formatEur }}</div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="list-group-item py-1">
                        <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                                <div class="widget-content-left center-elem mr-2 row-icon">
                                    <i class="pe-7s-right-arrow text-muted fsize-3"></i>
                                </div>
                                <div class="widget-content-left field-min-width text-right mr-1">
                                    <div class="widget-heading fsize-1">{{ $t('lifetime-right')}} PV.:</div>
                                </div>
                                <div class="widget-content-left">
                                    <div class="font-weight-bolder fsize-1">{{ user.totals.activeFiatRight|formatEur }}</div>
                                </div>
                            </div>
                        </div>
                    </li>




                    <li class="list-group-item py-1">
                        <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                                <div class="widget-content-left center-elem mr-2 row-icon">
                                    <i class="pe-7s-angle-left text-muted fsize-3"></i>
                                </div>
                                <div class="widget-content-left field-min-width text-right mr-1">
                                    <div class="widget-heading fsize-1">{{ $t('inactive-left')}}:</div>
                                </div>
                                <div class="widget-content-left">
                                    <div class="font-weight-bolder fsize-1">{{ user.totals.inactiveVolumeLeft|formatHtl }}</div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="list-group-item py-1">
                        <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                                <div class="widget-content-left center-elem mr-2 row-icon">
                                    <i class="pe-7s-angle-right text-muted fsize-3"></i>
                                </div>
                                <div class="widget-content-left field-min-width text-right mr-1">
                                    <div class="widget-heading fsize-1">{{ $t('inactive-right')}}:</div>
                                </div>
                                <div class="widget-content-left">
                                    <div class="font-weight-bolder fsize-1">{{ user.totals.inactiveVolumeRight|formatHtl }}</div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="list-group-item py-1">
                        <a class="btn-transition-text btn-transition btn btn-outline-primary mx-1 py-0" :href="'/#/downline/'+user.id">
                            {{$t('to-downline')}}
                        </a>
                        <a type="button" class="btn-transition-text btn-transition btn btn-outline-success mx-1 py-0"
                           :href="'/#/binary/'+user.id">
                            {{$t('to-binary')}}
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "UnilevelInfoBox",
    props: [
        "user"
    ],
    computed: {
        ...mapGetters(
            {
                profile: 'profile/unilevelData',
                isAdmin: 'user/isAdmin'
            }
        )
    },
    methods: {
        getRankName(val) {
            const rankName = [
                this.$i18n.t('rank-0'),
                this.$i18n.t('rank-1'),
                this.$i18n.t('rank-2'),
                this.$i18n.t('rank-3'),
                this.$i18n.t('rank-4'),
                this.$i18n.t('rank-5'),
                this.$i18n.t('rank-6'),
                this.$i18n.t('rank-7'),
                this.$i18n.t('rank-8'),
                this.$i18n.t('rank-9'),
                this.$i18n.t('rank-10'),
                this.$i18n.t('rank-11'),
            ];
            return rankName[val];
        },
        getSponsorLeg(val) {
            switch (val) {
                case 1:
                    return this.$i18n.t('leg-left');
                case 2:
                    return this.$i18n.t('leg-right');
                default:
                    return "Unknown"
            }
        }
    }
}
</script>